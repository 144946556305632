import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import OOPS_Img from "../images/404_image.jpg";

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  backgroundImage: `url(${OOPS_Img})`,
  backgroundSize: `cover`,
  backgroundPosition: `center`,
  position:`relative`
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

// markup
const NotFoundPage = () => {
  return (
    <div className="page-not-found">
      <>
        <Layout GQLPage="" Layout="Without_Banner" classNames={"search-header person-header news_details_wrapper details-header"} popular_search_slug="insights">
          <main style={pageStyles} className="page-content-found">
            <div className="overlay_bg"></div>

            <div className="page-content-inner">
              {/* <div className="title">Ooops!!...</div> */}
              <div className="sub-title">The page you're after seems to be missing.</div>
              <p className="para-content">It might have been removed, is temporarily unavailable or we have changed it's name. We're sorry for the incovenience, please give your search another try or contact us here...</p>
              <div className="btn-not-found">
                <Link className="btn" to="/">Back to Homepage</Link>
                <Link className="btn" to="/contact-us">Contact Us</Link>
              </div>
            </div>
          </main>
        </Layout>
      </>
    </div>
  )
}

export default NotFoundPage
